<template>
  <div>
    <section class="section">
      <div class="row">
        <div class="col">
          <Panel type="sheet" :between="true">
            <template v-slot:headline>{{ isEdit ? 'クーポン編集' : 'クーポン作成' }}</template>
            <template v-slot:headlineStatus v-if="isEdit">
              <ul class="tab" v-if="isExistCouponStatus">
                <li v-for="item in couponDetail.status" :key="item.index" class="tab-item">
                  <div class="tab-status">{{ item }}</div>
                </li>
              </ul>
            </template>
            <template v-slot:body>
              <div class="form">
                <section class="form-section">
                  <p class="form-headline">基本設定</p>
                  <FormRow :required="true">
                    <template v-slot:label>クーポンタイトル</template>
                    <template v-slot:labelNote>（50文字以内）</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <input
                          class="form-control"
                          :class="{ 'is-error': errors['name'] }"
                          type="text"
                          name="name"
                          v-trim
                          v-maxlength
                          maxlength="50"
                          v-model="formData.name"
                        />
                      </div>
                    </template>
                  </FormRow>
                  <FormRow :required="true" v-if="isEdit && isNotGroupAndClientChild">
                    <template v-slot:label>発行元</template>
                    <template v-slot:content>
                      <label class="form-check form-check-btn">{{ officeFlagName }}</label>
                    </template>
                  </FormRow>
                  <FormRow :required="true" v-if="isNotStoreGroupAndClientChild">
                    <template v-slot:label>対象店舗</template>
                    <template v-slot:content>
                      <label v-if="isEdit" class="form-check form-check-btn">{{ shopCouponName }}</label>
                      <SearchSelect
                        v-else
                        ref="searchSelectShop"
                        :hasAllOption="true"
                        :initialValue="formData.shopIds"
                        :isError="errors.shopIds"
                        :options="shopList"
                        :searchKeyMinLength="searchKeyLength"
                        :searchAction="searchAction"
                        :resetOptions="resetOptions"
                        :params="params"
                        :searchField="searchField"
                        :closeOnSelect="false"
                        @change-selection="handleShopIdsList"
                      />
                    </template>
                  </FormRow>
                  <FormRow :required="true" v-if="isShowTypeCoupon">
                    <template v-slot:label>クーポン配布タイプ</template>
                    <template v-slot:content>
                      <label v-if="isEdit" class="form-check form-check-btn">{{ typeCouponName }}</label>
                      <div class="form-content-row" v-else>
                        <ul class="listGrid">
                          <li v-for="item in couponFormTypeList" :key="item.index" class="listGrid-item">
                            <label class="form-radio form-radio-btn">
                              <input
                                class="form-radio-input"
                                type="radio"
                                :value="item.value"
                                v-model="formData.typeCoupon"
                                name="typeCoupon"
                              />
                              <span :class="{'form-radio-label': true, 'is-error': errors.typeCoupon }" >{{ item.label }}</span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </template>
                  </FormRow>
                  <FormRow>
                    <template v-slot:label>写真をアップロード</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <ul class="fileUpload">
                          <li class="fileUpload-item">
                            <Fileupload
                              @uploaded="uploadImage"
                              @removed="deleteImage"
                              :loading="isUploadLoading"
                              :initialUrl="initialImage"
                            />
                          </li>
                        </ul>
                      </div>
                    </template>
                  </FormRow>
                  <FormRow>
                    <template v-slot:label>クーポン説明</template>
                    <template v-slot:labelNote>（300文字以内）</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <textarea
                          class="form-control form-textarea"
                          :class="{ 'is-error': errors['description'] }"
                          name="description"
                          v-trim
                          v-maxlength
                          maxlength="300"
                          v-model="formData.description"
                        ></textarea>
                      </div>
                    </template>
                  </FormRow>
                  <FormRow v-if="isNotGroupAndClientChild">
                    <template v-slot:label>利用可能日時</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <DateRange
                          type="dateTime"
                          field="expirationDate"
                          startField="startDate"
                          endField="endDate"
                          :value="formData.expirationDate"
                          :errorField="errors.expirationDate"
                          @on-change="onChangeDateRange"
                        />
                      </div>
                      <div class="form-content-row">
                        <p>
                          利用可能日時を指定した場合、左側の枠が利用開始日時、右側の枠が利用終了日時となります。<br />
                          未指定の場合は、常に公開となります。
                        </p>
                      </div>
                    </template>
                  </FormRow>
                  <FormRow>
                    <template v-slot:label>発行枚数管理</template>
                    <template v-slot:toggle>
                      <label class="form-toggle">
                        <input
                          class="form-toggle-input"
                          type="checkbox"
                          name="remainingUseFlag"
                          v-model="formData.remainingUseFlag"
                          :disabled="isRemainingUseFlag"
                        />
                        <span class="form-toggle-set">
                          <span class="form-toggle-bg"></span>
                          <span class="form-toggle-circle"></span>
                        </span>
                      </label>
                    </template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <div class="form-group">
                          <div class="form-group-item">
                            <p v-if="isRemainingUseFlag">{{ formData.totalUse }}</p>
                            <input
                              v-else
                              class="form-control"
                              :class="{ 'is-error': errors['totalUse'] }"
                              type="tel"
                              name="totalUse"
                              v-number="'integer'"
                              maxlength="10"
                              v-model="formData.totalUse"
                              :disabled="!formData.remainingUseFlag"
                            />
                          </div>
                          <div class="form-group-item">
                            枚
                          </div>
                        </div>
                      </div>
                      <div class="form-content-row">
                        <p>
                          オンにした場合、発行枚数はお客様が使用した時点で減算します。<br />
                          （管理画面上は発行した時点の枚数です）<br />
                          オフにすると使用枚数に制限がなくなります。
                        </p>
                      </div>
                    </template>
                  </FormRow>
                  <FormRow v-if="isEdit">
                    <template v-slot:label>利用回数</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <div class="form-group">
                          <div class="form-group-item">
                            <p>{{ formData.totalUsed ? formData.totalUsed : '-' }}</p>
                          </div>
                          <div class="form-group-item">
                            回
                          </div>
                        </div>
                      </div>
                    </template>
                  </FormRow>
                  <FormRow v-if="isNotGroupAndClientChild">
                    <template v-slot:label>顧客利用制限</template>
                    <template v-slot:toggle>
                      <label class="form-toggle">
                        <input
                          class="form-toggle-input"
                          type="checkbox"
                          name="limitUseFlag"
                          v-model="formData.limitUseFlag"
                          :disabled="isLimitUseFlag"
                        />
                        <span class="form-toggle-set">
                          <span class="form-toggle-bg"></span>
                          <span class="form-toggle-circle"></span>
                        </span>
                      </label>
                    </template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <div class="form-group">
                          <div class="form-group-item">
                            <p v-if="isLimitUseFlag">{{ formData.limit }}</p>
                            <input
                              v-else
                              class="form-control"
                              :class="{ 'is-error': errors['limit'] }"
                              type="tel"
                              name="limit"
                              v-number="'integer'"
                              maxlength="3"
                              v-model="formData.limit"
                              :disabled="!formData.limitUseFlag"
                            />
                          </div>
                          <div class="form-group-item">
                            回/同一顧客
                          </div>
                        </div>
                      </div>
                      <div class="form-content-row">
                        <p>
                          オンにした場合、同一顧客が該当のクーポンを利用できる回数を変更します。<br />
                          オフにすると一人あたりの利用回数は1回のみとなります。
                        </p>
                      </div>
                    </template>
                  </FormRow>
                </section>
                <section class="form-section">
                  <p class="form-headline">注目表示機能</p>
                  <FormRow>
                    <template v-slot:label>注目表示</template>
                    <template v-slot:toggle>
                      <label class="form-toggle">
                        <input
                          class="form-toggle-input"
                          type="checkbox"
                          name="catchcopyUseFlag"
                          v-model="formData.catchcopyUseFlag"
                        />
                        <span class="form-toggle-set">
                          <span class="form-toggle-bg"></span>
                          <span class="form-toggle-circle"></span>
                        </span>
                      </label>
                    </template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <p>注目アイテムなど、用途に合わせて帯で強調することができます。</p>
                      </div>
                    </template>
                  </FormRow>
                  <FormRow>
                    <template v-slot:label>表示テキスト</template>
                    <template v-slot:labelNote>（50文字以内）</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <input
                          class="form-control"
                          :class="{ 'is-error': errors['text'] }"
                          type="num"
                          name="text"
                          v-trim
                          v-maxlength
                          maxlength="50"
                          v-model="formData.catchcopy.text"
                        />
                      </div>
                    </template>
                  </FormRow>
                  <FormRow>
                    <template v-slot:label>背景色</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <ul class="selectColor">
                          <li class="selectColor-item" v-for="color in backgroundTemplates" :key="color.id">
                            <label class="selectColor-btn">
                              <input
                                class="selectColor-input"
                                type="radio"
                                :value="color.id"
                                name="color"
                                v-model="formData.catchcopy.color"
                              />
                              <span
                                :class="['selectColor-label', { 'selectColor-white': isWhite(color) }, { 'is-error': errors['color'] }]"
                                :style="`${color.color}`">
                                {{ color.name }}
                              </span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </template>
                  </FormRow>
                </section>
                <section class="form-section">
                  <p class="form-headline">詳細項目</p>
                  <transition-group class="additionForm" name="fade" tag="div">
                    <div class="additionForm-item" v-for="(detail, index) in formData.details" :key="index">
                      <button class="additionForm-delete" type="button" @click="removeDetail(index)">
                        <i class="aikon aikon-cross"></i>
                      </button>
                      <FormRow>
                        <template v-slot:label>項目名</template>
                        <template v-slot:labelNote>（20文字以内）</template>
                        <template v-slot:content>
                          <div class="form-content-row">
                            <input
                              class="form-control"
                              :class="{ 'is-error': errors[`title${index}`] }"
                              type="text"
                              name="title"
                              v-trim
                              v-maxlength
                              maxlength="20"
                              v-model="formData.details[index].title"
                            />
                          </div>
                        </template>
                      </FormRow>
                      <FormRow>
                        <template v-slot:label>内容</template>
                        <template v-slot:labelNote>（100文字以内）</template>
                        <template v-slot:content>
                          <div class="form-content-row">
                            <textarea
                              class="form-control form-textarea"
                              :class="{ 'is-error': errors[`content${index}`] }"
                              name="content"
                              v-trim
                              v-maxlength
                              maxlength="100"
                              v-model="formData.details[index].content"
                            ></textarea>
                          </div>
                        </template>
                      </FormRow>
                    </div>
                  </transition-group>
                  <button class="btn btn-bd-main" type="button" @click="addDetail">項目追加</button>
                </section>
              </div>
            </template>
          </Panel>
        </div>
      </div>
    </section>
    <section class="section" v-if="isShowHistoryDistribute">
      <div class="row">
        <div class="col">
          <Panel type="sheet">
            <template v-slot:headline>クーポン個別配布履歴</template>
            <template v-slot:body>
              <div class="scrollX">
                <TableWithCheckbox
                  :storeModule="storeModule"
                  :dataTable="dataTable"
                  :count="couponIndividualCount"
                  :labels="labels"
                ></TableWithCheckbox>
              </div>
            </template>
            <template v-slot:footer>
              <PaginationLog
                ref="pagination"
                :subdomain="subdomain"
                :shopId="Number(shopId)"
                :listLength="couponIndividualCount"
                :modulePath="modulePath"
                :noResetParamsStatePath="true"
              ></PaginationLog>
            </template>
          </Panel>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, maxLength, minValue, requiredIf } from 'vuelidate/lib/validators';
import error from '@/mixins/plugin/error';
//component
import Fileupload from '@/components/Fileupload.vue';
import FormRow from '@/components/FormRow.vue';
import SearchSelect from '@/components/SearchSelect.vue';
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import PaginationLog from '@/components/PaginationLog.vue';
import DateRange from '@/components/DateRange.vue';
//contants
import { StatusConstants } from '@/constants/status';
import { EventConstants } from '@/constants/event';
//helpers
import { isDateWithMinMaxValue, isDateWithSameAsValue } from '@/helpers/validators';
import { formatDateAndTime } from '@/helpers/formatData';

export default {
  data: function() {
    return {
      formData: {
        name: '',
        image: '',
        description: '',
        expirationDate: {
          startDate: '',
          endDate: '',
        },
        remainingUseFlag: 0,
        totalUse: null,
        validFlag: 1,
        catchcopyUseFlag: 0,
        catchcopy: {
          text: '',
          color: null,
        },
        details: [
          {
            title: '',
            content: '',
          },
        ],
        shopIds: [],
        typeCoupon: '',
        limitUseFlag: 0,
        limit: null,
      },
      initialImage: '',
      isOldImage: true,
      isNewImage: false,
      isUploadLoading: false,
      searchAction: 'shop/getShopList',
      resetOptions: 'shop/RESET_SHOP_LIST',
      params: {
        status: StatusConstants.shop.approved.value,
        validFlag: true,
        searchCoupon: true
      },
      searchField: 'name',
      modulePath: 'coupon/getIndividualCoupons',
      storeModule: 'coupon',
      prefixs: ['expirationDate'],
      isRemainingError: false
    };
  },
  props: {
    pageName: {
      type: String,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    'formData.remainingUseFlag'(newVal, oldVal) {
      if (!newVal && !this.isRemainingUseFlag) {
        this.formData.totalUse = null;
      }
      if (this.isEdit && this.couponDetail?.remainingUseFlag && oldVal && !newVal) {
        this.$message.showError('notOffToggleRemainingCoupon');
        this.$nextTick(() => {
          this.formData.remainingUseFlag = oldVal;
        });
      }
    },
    'formData.limitUseFlag'(newVal, oldVal) {
      if (!newVal && !this.isLimitUseFlag) {
        this.formData.limit = null;
      }
      if (this.isEdit && this.couponDetail?.limitUseFlag && oldVal && !newVal) {
        this.$message.showError('notOffToggleUsedClientCoupon');
        this.$nextTick(() => {
          this.formData.limitUseFlag = oldVal;
        });
      }
    }
  },
  validations() {
    const formData = {
      name: { required, maxLength: maxLength(50) },
      description: { maxLength: maxLength(300) },
      shopIds: {},
      typeCoupon: {},
      totalUse: {},
      limit: {},
      catchcopy: {
        text: { maxLength: maxLength(50) },
        color: {},
      },
    };
    if ((!this.$permission.isStoreOrGroup() && !this.isGmoOrOfficeLoginStore) && !this.isEdit) {
      formData.shopIds = { required };
      formData.typeCoupon = { selectRequired: required };
    }
    if (this.formData.expirationDate.startDate || this.formData.expirationDate.endDate) {
      formData.expirationDate = {
        startDate: { selectRequired: required },
        endDate: {
          selectRequired: required,
          notRegistDateTime: isDateWithSameAsValue(this.formData.expirationDate?.startDate, this.formData.expirationDate?.endDate)
        },
      };
    }
    if (this.formData.remainingUseFlag) {
      formData.totalUse = { required, minValueCoupon: minValue(1), maxLength: maxLength(10) };
      if (this.isEdit && this.formData?.totalUse) {
        if (this.couponDetail?.totalUse === null && (Number(this.formData.totalUse) <= Number(this.couponDetail?.totalUsed))) {
          formData.totalUse = { remainingMoreThanTotalUsed: () => false };
        } else if (this.couponDetail?.limitUseFlag && Number(this.formData?.totalUse) < Number(this.formData?.limit)) {
          formData.totalUse = { remainingMoreThanLimit: isDateWithMinMaxValue(this.formData?.limit, this.formData?.totalUse, true) };
          this.isRemainingError = true;
        }
      }
    }
    if (this.formData?.limitUseFlag) {
      if (this.formData?.limit === null || this.formData?.limit === '') {
        formData.limit = { required };
      } else if (!this.isLimitUseFlag) {
        const newRemaining = (this.isEdit && this.couponDetail?.remainingUseFlag) ? this.formData?.remaining : this.formData?.totalUse;
        if (this.formData?.remainingUseFlag && this.formData?.totalUse && (Number(this.formData?.limit) > Number(newRemaining)) && !this.isRemainingError) {
          formData.limit = { notLimitMoreThanRemaining: () => false };
        } else {
          formData.limit = { limitWithMinMaxValue: isDateWithMinMaxValue(1, 999, false) };
        }
      }
    }
    if (this.formData.catchcopyUseFlag) {
      formData.catchcopy = {
        text: { required, maxLength: maxLength(50) },
        color: { selectRequired: required },
      };
    }
    formData.details = {
      $each: {
        title: {
          maxLength: maxLength(20),
          required: requiredIf(function(detailItem) {
            return detailItem.content ? true : false;
          }),
        },
        content: {
          maxLength: maxLength(100),
          required: requiredIf(function(detailItem) {
            return detailItem.title ? true : false;
          }),
        },
      },
    };
    return { formData };
  },
  mixins: [error],
  components: {
    FormRow,
    Fileupload,
    SearchSelect,
    TableWithCheckbox,
    PaginationLog,
    DateRange,
  },
  computed: {
    ...mapGetters({
      userInfo: 'auth/infor',
      backgroundTemplates: 'common/backgroundTemplates',
      couponDetail: 'coupon/couponDetail',
      shopList: 'shop/shopList',
      couponIndividualList: 'coupon/couponIndividualList',
      couponIndividualCount: 'coupon/couponIndividualCount',
      hasCustomersRole: 'auth/hasCustomersRole',
      isClientChild: 'auth/isClientChild',
      isShowCustomer: 'event/isShowCustomer',
      newShopId: 'shop/newShopId',
      newSubdomain: 'common/subdomain',
      isGmoOrOfficeLoginStore: 'common/isGmoOrOfficeLoginStore',
      isGmoOrOfficeLoginStoreAndStoreGroup: 'common/isGmoOrOfficeLoginStoreAndStoreGroup',
    }),
    subdomain() {
      return this.$permission.isStoreOrGroup() ? this.userInfo.event?.subdomain : this.isGmoOrOfficeLoginStore ?
        this.newSubdomain : this.$route.params?.subdomain;
    },
    shopId() {
      return this.$permission.isStoreOrGroup() ? this.userInfo.shop?.id : this.isGmoOrOfficeLoginStore ?
        this.newShopId : this.$route.params?.shopId;
    },
    id() {
      return this.$route?.params.id;
    },
    isWhite() {
      return (color) => { return color.name === '白'; }
    },
    searchKeyLength() {
      return EventConstants.SEARCH_KEY_MIN_LENGTH;
    },
    couponFormTypeList() {
      return Object.values(EventConstants.COUPON_FORM_TYPE);
    },
    listCouponRouter() {
      return this.isGmoOrOfficeLoginStoreAndStoreGroup ? 'ShopCoupon': 'EventCoupon';
    },
    shopCouponName() {
      return this.couponDetail?.shop?.name;
    },
    typeCouponName() {
      return this.couponFormTypeList?.[this.couponDetail?.typeCoupon]?.label;
    },
    officeFlagName() {
      return !this.couponDetail?.officeFlag ? this.couponDetail?.shop?.name :
        Object.values(EventConstants.roleName)?.[this.couponDetail?.officeFlag]?.label;
    },
    isOwnHistoryCoupon() {
      return this.isEdit && this.couponDetail?.officeFlag && this.couponDetail?.typeCoupon;
    },
    isShowInvidualName() {
      return this.isGmoOrOfficeLoginStoreAndStoreGroup ? !this.isShowCustomer : !this.hasCustomersRole;
    },
    isShowTypeCoupon() {
      if (this.isGmoOrOfficeLoginStore) return false;
      return this.isEdit ? this.isNotGroupAndClientChild : this.$permission.isGmoOrOffice();
    },
    isNotStoreGroupAndClientChild() {
      return (!this.$permission.isStoreOrGroup() && !this.isGmoOrOfficeLoginStore) && !this.isClientChild;
    },
    isNotGroupAndClientChild() {
      return !this.$permission.isGroup() && !this.isClientChild;
    },
    isShowHistoryDistribute() {
      return this.isOwnHistoryCoupon && this.isNotGroupAndClientChild;
    },
    isExistCouponStatus() {
      return this.couponDetail?.status?.length;
    },
    dataTable() {
      return this.couponIndividualList.map((item) => {
        return {
          ...item,
          userId: item?.id,
          createDate: formatDateAndTime(item.createDate),
        };
      });
    },
    labels() {
      return [
        { key: 'name', name: 'お名前', notShow: this.isShowInvidualName },
        { key: 'userId', name: 'user ID' },
        { key: 'createDate', name: '配布日' }
      ]
    },
    isRemainingUseFlag() {
      return this.couponDetail?.remainingUseFlag && this.isEdit;
    },
    isLimitUseFlag() {
      return this.couponDetail?.limitUseFlag && this.isEdit;
    }
  },
  methods: {
    addDetail: function() {
      this.formData.details.push({
        title: '',
        content: '',
      });
    },
    removeDetail(index) {
      this.formData.details.splice(index, 1);
      this.errors[`title${index}`] = false;
      this.errors[`content${index}`] = false;
    },
    handleShopIdsList(value) {
      this.formData.shopIds = value;
    },
    handleSubmit: async function() {
      this.$v.formData.$touch();
      if (this.isEdit && this.couponDetail?.totalUse === this.couponDetail?.totalUsed) {
        this.$message.showError('notEditUsedCoupon');
        return;
      }
      if (this.$v.formData.$invalid) {
        this.getErrors(this.$v.formData, 'coupon');
      } else {
        if (this.isEdit) {
          this.updateCoupon('coupon');
        } else {
          this.createCoupon('coupon');
        }
      }
    },
    async uploadImage(url) {
      this.isUploadLoading = true;
      this.isNewImage = true;
      this.formData.image = await this.$store.dispatch('common/uploadImage', {
        image: url,
      });
      this.isUploadLoading = false;
    },
    deleteImage() {
      this.formData.image = '';
      this.initialImage = '';
      this.isOldImage = false;
    },
    formatHourMinutes(data) {
      return data ? `${data['HH']}:${data['mm']}` : '';
    },
    formatData() {
      let data = {
        ...this.formData,
        remainingUseFlag: this.formData.remainingUseFlag ? 1 : 0,
        catchcopyUseFlag: this.formData.catchcopyUseFlag ? 1 : 0,
        details: this.formData.details.filter((item) => item.title !== '' && item.content !== ''),
        shopIds: this.isGmoOrOfficeLoginStoreAndStoreGroup ? [this.shopId] : this.formData?.shopIds?.map((item) => item.id),
      };
      if (this.isEdit && this.isOldImage && !this.isNewImage) delete data.image;
      if (this.isGmoOrOfficeLoginStoreAndStoreGroup) {
        delete data.typeCoupon;
      }
      return data;
    },
    async createCoupon() {
      const shopId = this.isGmoOrOfficeLoginStoreAndStoreGroup ? this.shopId : (this.formData?.shopIds?.[0]?.id === 0) ?
        this.shopList?.[1]?.id : this.formData?.shopIds?.[0]?.id;
      const result = await this.$store.dispatch('coupon/createCoupon', {
        subdomain: this.subdomain,
        shopId: shopId,
        data: this.formatData(),
        officeFlag : this.isGmoOrOfficeLoginStoreAndStoreGroup ? 0 : 1
      });
      if (result) {
        await this.$router.push({ name: this.listCouponRouter });
        this.$message.created('coupon');
      }
    },
    async updateCoupon() {
      const result = await this.$store.dispatch('coupon/updateCoupon', {
        subdomain: this.subdomain,
        shopId: this.shopId,
        id: this.id,
        data: this.formatData(),
        officeFlag : this.isGmoOrOfficeLoginStoreAndStoreGroup ? 0 : 1
      });
      if (result) {
        await this.$router.push({ name: this.listCouponRouter });
        this.$message.updated('coupon');
      }
    },
    async getDetail() {
      if (this.isEdit) {
        await this.$store.dispatch('coupon/getCouponDetail', {
          subdomain: this.subdomain,
          shopId: this.shopId,
          id: this.id,
          officeFlag : this.isGmoOrOfficeLoginStoreAndStoreGroup ? 0 : 1
        });
      }
    },
    onChangeDateRange(field, type, val) {
      this.formData[field] = {
        ...this.formData[field],
        [type]: val,
      }
    },
  },
  async mounted() {
    const loading = this.$loading.show();
    this.$store.commit(this.resetOptions);
    Promise.all([this.$store.dispatch('common/getBackgroundTemplateList'), this.getDetail()])
      .then(() => {
        if (this.isEdit) {
          this.formData = { ...this.formData, ...this.couponDetail };
          this.initialImage = this.formData.image?.url;
          if (this.$refs.pagination) this.$refs.pagination.resetPagination();
        }
      })
      .finally(() => {
        this.$loading.clear(loading);
      });
  },
};
</script>

<style></style>
